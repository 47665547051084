.sidebar-wrapper {
    @apply w-[188px] lg:flex lg:flex-wrap hidden content-start;
}

.sidebar-categories {
    @apply mb-6 w-full;
}

.sidebar-menu {
    @apply px-4;

    & li {
        @apply py-2.5 px-0.5 text-[13px] font-medium border-b-[#DDDDDD] border-b-[1px] leading-snug tracking-tighter;

        &:last-child {
            @apply border-b-0;
        }
    }

    & a {
        @apply hover:text-oidlRed;
    }
}

.sidebar-featured {
    @apply mb-6;
}

.sidebar-featured-menu {

    & li {
        @apply mb-3;

        &:last-child {
            @apply mb-0;
        }
    }

    & a {
        @apply block px-3.5 py-3 rounded text-xl font-bold text-white uppercase leading-tight tracking-tighter text-center;

    }
}

.mobile-menu {
    @apply hidden fixed inset-0 z-20 bg-white px-4 pb-4 pt-16 overflow-y-auto;

    &.is-active {
        @apply block;
    }
}

#mobile-search {

    &.is-active {
        @apply !block fixed inset-0 z-20 bg-white px-4 pb-4 pt-16 overflow-y-auto;
    }
}

.mobile-menu-part {
    @apply mb-8;

    & .mobile-menu-wrap {
        @apply hidden;
    }

    & h2 {
        @apply hidden;
    }

    &.is-open {

        & .mobile-menu-wrap {
            @apply block; 
        }

        & .mobile-menu-toggle {

            & svg {
                @apply rotate-180;
            }
        }
    }

    & .mobile-menu-toggle {
        @apply px-2.5 py-3 bg-oidlRed text-white font-bold w-full rounded flex items-center justify-between;

        & svg {
            @apply inline-flex;
        }
    }
}