.btn {
    @apply text-[13px] font-medium px-2 py-2.5 rounded-lg inline-flex cursor-pointer;

    &.btn-outline {
        @apply border-[#0000004d] border-[1px];
    }

    &.btn-with-icon {
        @apply items-center gap-x-1.5;
        & svg {
            @apply inline-flex;
        }
    }

    &.btn-orange {
        @apply text-white bg-oidlOrange;
    }

    &.btn-green {
        @apply text-white bg-[#6D9F88];
    }

    &:hover {
        @apply opacity-60;
    }
}