html {
    @apply scroll-smooth;
}

.main-wrapper {
    @apply lg:w-[calc(100%-424px)] w-full;
}

.page-title {
    @apply text-2xl leading-[1.1] font-bold text-black tracking-tight mb-8;
}

.page-subtitle {
    @apply text-xl font-bold tracking-tight mb-6 leading-[1.3];
}

.page-content {
    @apply font-medium text-base leading-[1.3] tracking-tight;

    & a {
        @apply underline;
    }

    & p {
        @apply mb-4;
    }

    & ul {
        @apply list-disc list-inside;

        & li {
            @apply mb-4;
        }
    }

    & ol {
        @apply list-decimal list-inside;

        & li {
            @apply mb-4;
        }
    }
}

.section-title {
    @apply font-bold text-[32px] text-black leading-tight tracking-tighter;
}

.footer-menu-box {
    @apply text-[13px];

    & h2 {
        @apply font-bold leading-[1.2] mb-2;
    }

    & ul {
        @apply  leading-[1.4];

        & li {
            @apply mb-2;
        }
    }
}

.mySlides {

    & a {
        &:after {
            content: '';
            background: rgb(0,0,0);
            background: linear-gradient(180deg, rgba(0,0,0,0.4) 31%, rgba(0,0,0,0.01) 55%);
            @apply absolute inset-0;
        }

        &:hover {
            @apply opacity-100;
        }
    }
    
}

.header-search .easy-autocomplete {
    @apply xl:!max-w-[600px] lg:!max-w-[250px]  !w-full;

    & input {
        @apply w-full;
    }
}

.add-to-cart-icon {

    & path {
        @apply lg:stroke-white stroke-black;
    }
}

.product-grid {
    @apply grid lg:grid-cols-4 grid-cols-2 w-full gap-x-4 gap-y-6;

    &.product-grid-scroll {
        @apply flex overflow-x-auto;

        & .product-loop {
            @apply lg:w-[350px] w-[50%] shrink-0;
        }
    }
}

body {
    &.menu-is-open {
        @apply overflow-hidden h-screen;
    }
}

.card-box {
    @apply text-sm; 

    & .card-box-header {
        @apply bg-[#F6F6F6] px-3 py-2 font-bold;
    }

    & .card-box-content {
        @apply px-3 py-2;
    }
}

.jconfirm {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.jconfirm-holder {
    background-color: #fff;
    border: 2px solid #000;
    border-radius: 10px;
    margin: auto;
}

a {

    &:hover {
        @apply opacity-80;
    }
}

#master-footer {

    & a {
        @apply hover:text-oidlRed;
    }
}
