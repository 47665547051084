.header-search {

    & .input-search {
        @apply border-[#00000080] border-[1px] rounded-full pl-4 pr-9 py-2.5 text-[13px] font-medium max-w-full;
    }

    & #main-header-search-form {
        @apply max-w-full;
    }
}

input.qty-box {
    @apply border-[#6D9F88] border-[1px] rounded-md text-[13px] text-center h-[33px] max-w-full px-2; 
}

input[type="radio"],
input[type="checkbox"] {
    @apply inline-block w-4 h-4;
}

label {
    @apply text-xs mb-2;

    &.radio-label {
        @apply flex items-center;

        & input {
            @apply mr-2;
        }
    }
}

.form-heading {
    @apply text-2xl font-bold leading-[1.1] tracking-tight py-2 border-t-[1px] border-b-[1px] border-black;
}

.form {

    & label {
        @apply block text-xs mb-2;
    }

    & .form-row {
        @apply mb-5 relative;
    }

    & input[type="radio"],
    & input[type="checkbox"] {
        @apply inline-block w-4 h-4;
    }

    & input,
    & textarea,
    & .form-input {
        @apply block w-full max-w-[400px] rounded-md border-[1px] border-black;
    }

    & label.error {
        font-size: 12px;
        color: #A51129;
        position: absolute;
        bottom: -25px;
        display: block;
        font-weight: bold;
    }
}

.message-error {
    @apply text-oidlRed;
}

#eac-container-keywords {
    @apply absolute w-full;
}