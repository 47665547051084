@import "components/fonts.pcss";
@import "components/buttons.pcss";
@import "components/forms.pcss";
@import "components/sidebar.pcss";
@import "components/product.pcss";
@import "components/main.pcss";

@tailwind base;
@tailwind components;
@tailwind utilities;
