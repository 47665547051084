.product-loop {
    @apply bg-white px-4 pb-4 pt-3 rounded-md flex flex-wrap;

    &.product-loop-variations {
        @apply items-start content-start;
    }

    & .product-loop-image {
        @apply mb-4 relative aspect-square mx-auto w-full;

        & a {
            @apply flex items-center h-full justify-center;
        }
    }

    & .product-loop-title {
        @apply text-base text-black font-bold leading-tight tracking-tighter mb-6 w-full;

        &:hover {
            @apply opacity-100 text-oidlRed;
        }  
    }

    & .product-loop-price {
        @apply self-end w-full;
    }

    & .product-loop-price-discount {
        @apply text-[#969696] text-[13px] font-medium line-through mb-1.5;
    }

    & .product-loop-price-regular {
        @apply text-black text-xl font-bold;
    }

    & .product-mark {
        @apply absolute left-0 top-0;

        & span {
            @apply rounded px-2 p-1 text-white uppercase text-[13px] font-medium leading-none;

            &.product-mark-mega {
                @apply bg-oidlLightRed;
            }

            &.product-mark-sale {
                @apply bg-oidlOrange;
            }

            &.product-mark-new {
                @apply bg-oidlGreen;
            }

            &.product-mark-sold {
                @apply bg-black;
            }
        }
    }

    &.product-loop-small {
        
        & .product-loop-image {
            @apply mb-2 relative aspect-square mx-0 w-auto;

            & a {
                @apply flex items-start h-full justify-start mt-8;
            }
        }

        & .product-mark {
        
            & span {
                @apply px-1.5 p-1 text-[10px];
            }
        }

        & .product-loop-title {
            @apply text-sm leading-[1.2] mb-4;
        }

        & .product-loop-price-regular {
            @apply text-lg;
        }

        & .product-loop-price-discount {
            @apply mb-1.5 text-[11px];
        }

    }
}

.single-product {
    @apply lg:w-[calc(100%-424px)] w-full;

    & .product-title {
        @apply text-2xl mb-3 leading-[1.1] font-bold text-black tracking-tight;
    }

    & .product-code {
        @apply text-[13px] leading-[1.3] text-[#00000099] tracking-tight mb-8 font-medium;
    }

    & .product-price {
        @apply text-[28px] leading-[1.2] tracking-tight font-bold text-black mb-1;
    }

    & .product-old-price {
        @apply text-base leading-[1.2] font-medium tracking-tight mb-1.5 text-[#969696] line-through;
    }

    & .add-to-cart {

        & input {
            @apply p-3 rounded-md text-center border-[1px] border-[#6D9F88] w-full h-full text-xl cursor-pointer;

            &:hover {
                @apply opacity-60;
            }
        }

        & .btn-add-to-cart {
            @apply bg-[#6D9F88] text-white font-bold text-xl leading-[1.6];
        }
    }

    & .product-details-item-header {
        @apply cursor-pointer py-4 font-bold text-base leading-[1.3] tracking-tight relative;

        &:after {
            content: '';
            background-image: url(/images/plus.png);

            @apply w-6 h-6 absolute inset-y-0 m-auto right-0 block bg-contain;
        }
    }

    & .product-details-item {
        @apply px-3 border-t-[1px] border-[#00000099];

        &:last-child {
            @apply border-b-[1px];
        }

        &.is-active {

            & .product-details-item-content {
                @apply block pb-4 pt-3;
            }

            & .product-details-item-header {

                &:after {
                    background-image: url(/images/minus.png);
                }
            }
        }

        & .product-details-item-content {
            @apply hidden;

            & ul {
                @apply list-disc list-inside;
            }
        }
    }

    & .product-mark {

        & span {
            @apply rounded px-2 p-1 text-white uppercase text-[13px] font-medium leading-none;

            &.product-mark-mega {
                @apply bg-oidlLightRed;
            }

            &.product-mark-sale {
                @apply bg-oidlOrange;
            }

            &.product-mark-new {
                @apply bg-oidlGreen;
            }

            &.product-mark-sold {
                @apply bg-oidlRed;
            }
        }
    }
}

.slikaproizvoda #slider {
    @apply aspect-square p-0 flex items-center;
}

#carousel.flexslider .slides > li {
    @apply aspect-square h-auto !max-w-[88px];
}

#carousel.flexslider {
    @apply bg-transparent;
}

.flex-direction-nav a.flex-next,
.flex-direction-nav a.flex-prev {
    @apply w-8 h-8 text-[0px] bg-contain block;

    &:before {
        @apply hidden;
    }
}

.flex-direction-nav a.flex-next {
    background-image: url(/images/arrow-right.png);
}

.flex-direction-nav a.flex-prev {
    background-image: url(/images/arrow-left.png);
}

.rating-wrapper {
    @apply w-full max-w-[620px] mx-auto mb-10;

    & .rating-row {
        @apply flex items-center py-1 px-3 text-base leading-[1.5] text-black;

        & .no_stars {
            @apply w-[34px] flex items-center;
        }

        & .no_graph {
            @apply flex-1;
        }

        & .no_procent {
            @apply w-[52px] text-center;
        }

        & .no_graph_inner {
            @apply block w-full bg-[#D9D9D9] h-2.5 rounded-lg;
        }

        & .no_graph_inner_rating {
            @apply block bg-[#6D9F88] h-2.5 rounded-lg;
        }
    }
}

.single-comment {
    @apply p-4 border-b-[1px] border-[#DDDDDD];

    &:last-child {
        @apply border-b-[0px]
    }
}

.comment-name {
    @apply font-bold text-xl leading-6 mb-2 block;
}

.comment-date {
    @apply text-[13px] leading-4 mb-4 block text-[#4f4f4f];
}

.comment-content {
    @apply text-base leading-[1.6];
}

.breadcrumbs {
    @apply text-[13px] font-medium mb-6 text-black leading-[1.3] tracking-tight;
}

.category-box {
     @apply bg-white px-4 pb-4 pt-3 rounded-md flex flex-wrap;
}

.deklaracija-modal {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    box-sizing: border-box;
    padding: 10px;
}

.deklaracija-modal_content {
    background-color: #fff;
    border-radius: 10px;
    width: 100%;
    max-width: 600px;
    padding: 10px;
    margin: auto;
    top: 50%;
    transform: translateY(-50%);
    position: relative;
    box-sizing: border-box;
}

.btn.btn-close-deklaracija {
    position: absolute;
    right: 5px;
    top: 5px;
    width: 20px;
    height: 20px;
    color: #fff;
    border-radius: 50%;
    font-weight: 700;
    background-color: #000;
    text-align: center;
    text-decoration: none;
    padding: 0 !important;
    display: block;
}