@charset "utf-8";

.hederomotaljka {
    float: left;
    width: 100%
}

.hederlogo {
    float: left;
    width: 30%;
    margin: 10px 20px
}

.hederlogo h1 {
    margin: 0px !important
}

.hedertelefoni {
    float: right;
    width: 20%;
    margin-top: 10px
}

.hedertporuka {
    float: right;
    width: 10%;
    padding: 10px;
    margin-top: 10px
}

.hederstrelice {
    float: right;
    width: 50%;
    text-align: right;
    padding-right: 15px
}

.hederstrelice ul {
    list-style-type: none;
    margin: 0px
}

.hederstrelice ul li {
    display: block;
    float: right;
    margin-left: 5px;
    background: #ccc;
    padding: 3px 10px;
    border-radius: 4px 4px 0px 0px
}

.hederstrelice ul li:hover {
    background: #f58220
}

.hederstrelice ul li:hover a {
    color: #000
}

.hederstrelice ul li a:hover {
    color: #fff !important;
    text-decoration: none !important
}

.naslovnaslika {
    width: 100%;
    /*background: url(../images/webshop.jpg);*/
    background-repeat: no-repeat;
    text-align: center;
    background-attachment: scroll;
    float: left
}

.glavnaomotaljka {
    float: left;
    width: 100%
}

.pretraga {
    float: right;
    width: 83%
}

.levisajdbar {
    width: 15%;
    float: left;
    margin: 0px 1%
}

.centralnideo {
    float: left;
    width: 66%
}

.centralnideobezdesnekolone {
    float: left;
    width: 83%
}

.desnisajdbar {
    float: right;
    width: 20%;
    margin: 0;
}

.desnisajdbar a {
    display: block;
    margin-bottom: 2px
}

.desnisajdbar img {
}

.futer {
    float: left;
    width: 100%;
    background: #dadada;
    border-top: solid 5px #ccc;
    color: #000;
    font-size: 13px
}

.futer h2 {
    color: #000;
    font-size: 16px;
    margin: 0px 0px 5px;
    border-bottom: dotted 2px #ccc
}

.webnimeni {
    border: solid 5px #dadada
}

.webnimeni h2 {
    background: #BF1E2D;
    color: #fff;
    margin: 0px;
    font-size: 17px;
    padding: 5px 0px 5px 10px
}

.dugmetaispodmenija {
    width: 100%;
    margin: 5px 0px
}

.dugmetaispodmenija ul {
    list-style-type: none;
    margin: 0px;
    padding: 0px
}

.dugmetaispodmenija ul li {
    display: block;
    padding: 10px;
    font-size: 20px;
    color: #fff;
    margin: 0px 0px 5px 0px;
    font-weight: bold
}

.dugmetaispodmenija a {
    color: #fff;
    text-decoration: none;
    display: block;
}

.webnirobnemarke {
    border: solid 5px #dadada;
    float: left;
    width: 97%
}

.webnirobnemarke h2 {
    background: #BF1E2D;
    color: #fff;
    margin: 0px;
    font-size: 17px;
    padding: 5px 0px 5px 10px
}

.webnirobnemarke a {
    display: block;
    margin: 3px 3px 5px;
    padding: 2px
}

.webnirobnemarke a:hover {
    text-decoration: none;
    background: #bf1e2d;
    color: #fff
}

.svemarke {
    background: url(../images/arrow_red.jpg);
    background-position: left middle;
    background-repeat: no-repeat;
    padding-left: 20px !important;
    color: #333;
    font-size: 15px;
    text-decoration: none
}

.webnisocijal {
    float: left;
    width: 100%;
    margin: 5px 0px
}

.webnigledaniproizvodi {
    float: left;
    width: 100%;
    margin: 0px
}

.webnigledaniproizvodi h2 {
    background: #BF1E2D;
    color: #fff;
    margin: 0px 0px 5px;
    font-size: 17px;
    padding: 5px 0px 5px 10px
}

.webnigledaniproizvodi img {
    border: solid 1px #dadada;
    padding: 2px
}

.webnigledaniproizvodi table tr:nth-child(5) {
    display: none
}

.bannerlevodole {
    float: left;
    width: 100%;
    margin: 5px 0px
}

.bannerlevodole img {
    width: 100%;
    height: auto
}

.bannerlevodole > a {
    display: block;
    margin-bottom: 1px
}

.webnipretraga {
    float: left;
    width: 100%;
    background: #ccc;
    padding: 5px 0px 3px;
    position: relative
}

.webnipretragainput {
    position: absolute;
    top: 7px;
    left: 300px;
    right: 200px
}

@media screen and (max-width: 1100px) and (min-width: 851px) {
    .webnipretragainput {
        left: 200px
    }
}

@media screen and (max-width: 850px) {
    .webnipretragainput {
        left: 130px
    }
}


.webnipretraganaslov {
    float: left
}

.webnipretragadugme,
.webnipretragakorpa {
    float: right
}

.webnipretragadugme,
.webnipretragakorpa {
    margin-right: 10px
}

.basket_links {
  font-family: Arial,Verdana,Helvetica,sans-serif;
  font-size: 12px;
  text-decoration: none;
  color: #bf1e2d;
}

.td12_pad2 {
  font-size: 12px;
  padding: 2px;
}

.basket_table_header {
  font-family: Arial,Verdana,Helvetica,sans-serif;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
}
.gray-background {
  background-color: #ededed;
  padding: 2px 10px;
}

.dugmetaispodmenijamob {
    display:none
}

@media(max-width: 500px){
    
    .bezpdvmob {
        display: none;
    }
}

.webnipretraganaslov {
    font-size: 15px;
    color: rgb(162, 12, 26);
    padding: 7px 0;
    width: 85px;
    text-align: center
}

.webnipretraga input {
    border: solid 1px #ccc;
    padding: 7px
}

.webnipretraga .webnipretragainput input {
    width: 100%;
    box-sizing: border-box
}

.webnipretraga select {
    border: solid 1px #ccc;
    padding: 7px
}

.webnipretraga button {
    border: none !important
}

.futermojestrane {
    float: left;
    width: 27%;
    margin: 10px 3%;
    text-align: center
}

.futerpomoc {
    float: left;
    width: 34%;
    margin: 10px 0px;
    text-align: center
}

.futeronama {
    float: right;
    width: 27%;
    margin: 10px 3%;
    text-align: center
}

.futerkopirajt {
    float: left;
    width: 100%;
    text-align: center;
    color: #fff;
    background: #333;
    border-top: dotted 2px #dadada;
    padding: 10px 0px
}

.futerkopirajt a {
    color: #BF1E2D;
    text-decoration: none
}

.futerkopirajt a:hover {
    color: #fff
}

.futerlinkovi a {
    display: block;
    float: left;
    color: #000;
    text-decoration: none;
    width: 100%;
    margin-bottom: 5px;
    font-weight: 700;
}

.futerlinkovi a:hover {
    color: #BF1E2D
}

.floatleft {
    float: left;
    width: 100%;
    font-size: 12px
}


.proizvodlevo {
    float: left;
    width: 45%
}

.ispodslike {
    float: left;
    width: 100%
}

.desnoodslike {
    float: right;
    width: 50%;
    font-size: 12px;
    margin-right: 3%;
    margin-bottom: 25px
}

.desnoodslike ul {
    margin: 0px
}

.desnoodslikebrand {
    float: right;
    width: 300px;
    text-align: right;
    margin-right: 10px
}

.markicaproizvoda {
    position: absolute;
    top: 0px;
    right: 0px
}

.naslovniproizvodcena {
    position: absolute;
    bottom: 0px
}

.webnirobnemarkelevo {
    background: #f58220;
    color: #fff;
    font-size: 12px;
    width: 49%;
    float: left;
    font-weight: bold;
    text-align: center
}

.webnirobnemarkedesno {
    background: #bf1e2d;
    color: #fff;
    font-size: 12px;
    width: 49%;
    float: right;
    font-weight: bold;
    text-align: center
}

.webnifacebook {
    background: #3b5998;
    text-align: center;
    float: left;
    width: 33%
}

.webnitwitter {
    background: #56a3d9;
    text-align: center;
    float: left;
    width: 32%;
    margin: 0px 1%
}

.mobilnimeniji {
    display: none
}

.webniproizvoddesno table {
    width: 100% !important
}

.responsivekorpa {
    display: none
}

.hedertelefonimob {
    display: none
}

.hederlogomob {
    display: none
}

.mobilnigornjimeni {
    display: none
}

.categoryimage {
    height: 100px;
    margin-bottom: 5px;
    overflow: hidden
}

.kategorijawraper .categoryname a {
    text-decoration: none;
    color: #f58220;
    text-transform: uppercase;
    font-size: 15px
}

.podkategorijaproizvod {
    position: relative;
    width: 30%;
    margin: 0px 1% 10px;
    padding: 0 0 40px 0;
    border-bottom: solid 1px #ccc;
    text-align: center
}

.podkategorijaproizvod:after {
    border-left: 1px solid #ccc;
    content: "";
    display: block;
    height: 80%;
    left: -2%;
    position: absolute;
    top: 10%
}

.podkategorijaproizvod:nth-child(3n + 1):after {
    display: none;
}

.podkategorijaproizvodslika {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.dugmencedetaljnije {
    display: block;
    padding: 5px;
    text-decoration: none !important;
    color: #fff !important;
    background: #f58220;
    text-transform: uppercase;
    width: 100px;
    float: right
}

.dugmencedodajukorpu {
    display: block;
    padding: 5px;
    text-decoration: none !important;
    color: #fff !important;
    background: #bf1e2d;
    text-transform: uppercase;
    width: 120px;
    float: left;
    font-size: 12px;
    border: 0;
    box-shadow: none;
    border-radius: 0px;
    height: 25px
}

.dugmencedodajukorpu:hover {
    cursor: pointer
}

.podkategorijaproizvodmarkica {
    position: absolute;
    top: 0px;
    right: 0px
}

.sajdbarfilterinaslov {
    background: #dadada;
    font-weight: bold;
    color: #333;
    padding: 5px
}

.proizvoduputstvo {
}

.podkategorijaproizvoddugmici {
    position: absolute;
    bottom: 5px;
    width: 100%
}

.podkategorijaproizvoddugmicilevo {
    width: 49%;
    float: left
}

.podkategorijaproizvoddugmicidesno {
    width: 49%;
    float: right
}

.products_header {
    margin: 3px 0px
}

.dugmetaispodmenijamob {
    display: none
}

@media screen and (max-width: 1500px) {
    .podkategorijaproizvoddugmici {
        margin: 5px 0px 0px
    }
}

@media screen and (max-width: 1100px) {
    .desnisajdbar {
        display: none
    }

    .hedertporuka {
        display: none
    }

    .hedertelefoni {
        width: 30%
    }

    .centralnideo {
        width: 80%
    }
}

@media screen and (max-width: 850px) {

    .webnipretraganaslov {
        display: none
    }

    .hederstrelice {
        width: 60%
    }

    .left_sidebar_menu_links {
        font-size: 12px
    }

    .webnisocijal img {
        width: 80%;
        height: auto
    }

    .webniproizvoddesno {
        display: none
    }

    .responsivekorpa {
        display: block
    }

    .proizvodinapopustu {
        display: none
    }

    .hedertelefonimob {
        display: block;
        float: right;
        width: 40%;
        margin-top: 3px;
        text-align: center;
        padding: 0px !important
    }

    .hedertelefonimob a {
        font-size: 18px
    }

    .hedertelefoni {
        display: none
    }

    .podkategorijaproizvod {
        width: 100%;
        margin: 0px 0px 10px;
        height: auto
    }

    .podkategorijaproizvod:nth-child(2n):after {
        display: none
    }

    .category-product.podkategorijaproizvod::after {
        display: none !important;
    }
}

@media screen and (max-width: 780px) {
    .levisajdbar {
        width: 20%
    }

    .pretraga {
        width: 78%
    }

    .centralnideo {
        width: 78%
    }

    .hederstrelice {
        width: 100%
    }

    .centralnideobezdesnekolone {
        width: 78%
    }

    .slikaproizvoda #carousel > ul.flex-direction-nav {
        display: none !important;
    }
}

@media screen and (max-width: 620px) {

    .categoryimage {
        display: none
    }

    .webnipretragainput {
        right: 200px
    }

    .levisajdbar {
        display: none
    }

    .centralnideo {
        width: 98%;
        padding-left: 2%
    }

    .centralnideobezdesnekolone {
        width: 100%
    }

    .pretraga {
        width: 100%
    }

    .webnipretragainput {
        left: 10px
    }

    .hederlogo {
        width: 100%;
        margin: 5px 0px;
        text-align: center
    }

    .hedertelefoni {
        width: 100%;
        text-align: center;
        margin-top: 0px
    }

    .mobilnimeniji {
        display: block;
        width: 99%;
        float: left;
        margin: 2px 0px
    }

    .mobilnimenikategorije {
        float: left;
        width: 100%;
        text-align: center
    }

    .mobilnimenibrendovi {
        float: right;
        width: 100%;
        text-align: center
    }

    .malimenubrendovi {
        display: none
    }

    .mobilnimenibrendovi {
        display: none
    }

    .mobilnidugmici {
        display: block;
        width: 95%;
        padding: 5px 10px;
        background: #bf1e2d;
        color: #fff;
        margin: 0px auto 5px;
        text-decoration: none !important;
        text-transform: uppercase
    }

    .hederstrelice ul {
        padding: 0px
    }

    .hederstrelice ul li {
        width: 18%;
        text-align: center;
        border-radius: 0px;
        margin: 0px 1%;
        padding: 3px 0px 6px
    }

    .naslovniproizvodcena {
        position: relative
    }

    .naslovniproizvoddalje {
        position: relative
    }

    .hederlogomob {
        display: block;
        width: 40%;
        margin: 5px 3px;
        text-align: left;
        float: left
    }

    .hederlogo {
        display: none
    }

    .webnibredkumbs {
        display: none
    }

    .hederstrelice {
        display: none
    }

    /* .naslovnaslika {
        height: 200px
    } */

    .mobilnigornjimeni {
        display: block
    }

    .dugmetaispodmenijamob {
        display: block;
        width: 100%;
        margin: 0px 0px 2px;
        float: right
    }

    .dugmetaispodmenijamob ul {
        list-style-type: none;
        margin: 0px;
        padding: 0px
    }

    .dugmetaispodmenijamob ul li {
        display: block;
        padding: 5px;
        font-size: 15px;
        color: #fff;
        margin: 0px 0px 2px 0px;
        font-weight: bold
    }

    .dugmetaispodmenijamob a {
        color: #fff;
        text-decoration: none;
        display: block;
        text-align: center;
    }
}

@media screen and (max-width: 500px) {

    .glavnaomotaljka {
        overflow: hidden
    }

    .centralnideobezdesnekolone tr {
        font-size: 9px
    }

    .products_sort_links {
        font-size: 9px;
        text-transform: uppercase
    }

    .products_sort_links img {
        width: 12px;
        height: 12px
    }

    .centralnideobezdesnekolone tr:last-child td tr:nth-child(3) tr:nth-child(3) tr:first-child {
        display: block
    }

    .text12 {
        text-align: center
    }

    .malimenubrendovi a {
        width: 22%
    }

    .hederstrelice ul li {
        width: 23%
    }

    .hederstrelice ul li:nth-child(4) {
        display: none
    }

    .centralnideobezdesnekolone {
        width: 100%
    }

    .proizvodlevo {
        width: 100%
    }

    .desnoodslike {
        width: 100%;
        margin: 0px
    }

    .categoryimage img {
        display: none
    }

    .kategorijawraper .categoryname a {
        text-decoration: none;
        color: #333;
        text-transform: uppercase;
        font-size: 12px;
        background: #eee;
        padding: 7px 3px;
        display: block;
        width: 98%
    }

    .kategorijadesnoposle {
        display: none
    }

    .kategorijalevo {
        width: 99%
    }

    .kategorijacentar {
        width: 99%
    }

    .kategorijadesno {
        width: 99%
    }

    .proizvoduputstvo {
        display: none
    }

    .proizvodekstraslike {
        width: 98% !important
    }

    .slikaproizvoda {
        height: auto
    }

    .crvenosivastafna .text12:first-child {
        display: none
    }

    .webnisifraproizvoda {
        text-align: center
    }

    .obavestenje_proizvod_na_akciji {
        text-align: center
    }

    .kategorijawraper {
        width: 98%
    }

    
}

@media screen and (max-width: 400px) {

    .naslovnaslika {
        display: none !important
    }

    .webnipretragainput {
        top: 4px;
        right: 100px;
        width: 150px;
        max-width: 150px
    }

    .webnipretragadugme {
        float: right
    }

    .webnipretragadugme input {
        width: 75px;
        height: 26px
    }

    .webnipretragakorpa img {
        width: 75px;
        height: auto
    }

    .hederlogo img {
        width: 300px;
        height: auto
    }

    .malimenubrendovi a {
        width: 30%
    }

    .kategorijalevo {
        width: 100%;
        float: left
    }

    .kategorijacentar {
        width: 100%;
        float: left
    }

    .kategorijadesno {
        width: 100%;
        float: left
    }
}

@media screen and (max-width: 340px) {
    .webnipretragainput {
        width: 130px;
        max-width: 130px
    }
}

#containermain {
    min-height: 100%;
    margin: 0px;
    padding: 0;
    position: relative
}

.footer {
    background-image: url('../../images/footer_bgr.jpg');
    background-repeat: repeat-x
}

.search {
    background-image: url('../../images/search_bgr.jpg');
    background-repeat: repeat-x
}

.search {
    background-image: url('../../images/search_bgr.jpg');
    background-repeat: repeat-x
}

.header_links {
    font-family: Arial, Verdana, Helvetica, sans-serif;
    font-size: 12px;
    text-decoration: none;
    color: #000000
}

.header_links:hover {
    font-family: Arial, Verdana, Helvetica, sans-serif;
    font-size: 12px;
    text-decoration: underline;
    color: #000000
}

.text {
    font-family: Arial, Verdana, Helvetica, sans-serif;
    font-size: 13px;
    text-decoration: none;
    color: #000000
}

.input_text {
    font-family: Arial, Verdana, Helvetica, sans-serif;
    font-size: 12px;
    text-decoration: none;
    color: #000000
}

.text14_red_bold {
    font-family: Arial, Verdana, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: bold;
    color: #BF1E2D
}

.text12 {
    font-family: Arial, Verdana, Helvetica, sans-serif;
    font-size: 12px;
    text-decoration: none;
    color: #000000
}

.td12_pad20 {
    font-size: 12px;
    padding-left: 20px;
    padding-bottom: 2px;
    padding-right: 2px;
    padding-top: 2px
}

.ul_red {
    color: #BF1E2D;
    margin-left: -20px
}

.links_red12 {
    font-family: Arial, Verdana, Helvetica, sans-serif;
    font-size: 12px;
    text-decoration: none;
    color: #BF1E2D
}

.left_sidebar_links {
    font-family: Arial, Verdana, Helvetica, sans-serif;
    font-size: 12px;
    font-weight: normal;
    text-decoration: none;
    color: #BF1E2D
}

.left_sidebar_links a {
    text-decoration: none;
    color: #BF1E2D
}

.left_sidebar_links:hover,
.left_sidebar_links a:hover {
    text-decoration: underline;
    color: #BF1E2D
}

.left_sidebar_menu_links {
    font-family: Arial, Verdana, Helvetica, sans-serif;
    font-size: 14px;
    text-decoration: none;
    color: #333333
}

.left_sidebar_menu_links:hover {
    font-family: Arial, Verdana, Helvetica, sans-serif;
    font-size: 14px;
    text-decoration: underline;
    color: #333333
}

.left_sidebar_viewed_prod_links {
    font-family: Arial, Verdana, Helvetica, sans-serif;
    font-size: 11px;
    text-decoration: none;
    color: #BF1E2D
}

.left_sidebar_viewed_prod_links:hover {
    font-family: Arial, Verdana, Helvetica, sans-serif;
    font-size: 11px;
    text-decoration: underline;
    color: #BF1E2D
}

.myMenu li a:link {
    color: #fff;
    text-decoration: none;
}

#promocijaHolder label {
    font-size: 12px;
}

.webnigledaniproizvodi {
    border: solid 5px #dadada;
    box-sizing: border-box;
}

.webnigledaniproizvodi h3 {
    background: #dadada;
    font-weight: bold;
    color: #333;
    padding: 5px;
    font-size: 16px;
    margin: 0;
}

.webnigledaniproizvodi ul {
    list-style-type: none;
    padding: 5px;
    margin: 0;
}

.main_ordered_header {
    font-family: Arial, Verdana, Helvetica, sans-serif;
    font-size: 18px;
    font-weight: normal;
    padding-left: 10px;
    text-decoration: none;
    color: #F58220
}

.main_ordered_header a {
    text-decoration: none;
    color: #F58220
}

.main_ordered_product_name {
    font-family: Arial, Verdana, Helvetica, sans-serif;
    font-size: 13px;
    padding: 10px;
    font-weight: bold;
    color: #BF1E2D
}

.main_ordered_product_name a {
    color: #BF1E2D;
    text-decoration: none
}

.main_ordered_price {
    font-family: Arial, Verdana, Helvetica, sans-serif;
    font-size: 16px;
    color: #000;
}

.podkategorijaproizvodcena .products_price {
    font-size: 24px;
}

.main_ordered_discount_price {
    font-family: Arial, Verdana, Helvetica, sans-serif;
    font-size: 15px;
    text-decoration: line-through;
    color: #BF1E2D
}

.categories_header {
    font-family: Arial, Verdana, Helvetica, sans-serif;
    font-size: 18px;
    font-weight: normal;
    padding-top: 3px;
    padding-bottom: 3px;
    text-decoration: none;
    color: #F58220
}

.categories_header a {
    text-decoration: none;
    color: #F58220
}

.categories_header:hover,
.categories_header a:hover {
    text-decoration: underline;
    color: #F58220
}

.category_all_subcategories_links {
    font-family: Arial, Verdana, Helvetica, sans-serif;
    font-size: 12px;
    text-decoration: none;
    color: #F58220
}

.category_all_subcategories_links:hover {
    font-family: Arial, Verdana, Helvetica, sans-serif;
    font-size: 12px;
    text-decoration: underline;
    color: #F58220
}

.brand_products_header {
    font-family: Arial, Verdana, Helvetica, sans-serif;
    font-size: 24px;
    padding-left: 20px;
    text-decoration: none;
    color: #BF1E2D;
    margin: 0 0 10px 0
}

.products_sort_links {
    font-family: Arial, Verdana, Helvetica, sans-serif;
    text-decoration: none;
    color: #000000
}

.products_sort_links:hover {
    font-family: Arial, Verdana, Helvetica, sans-serif;
    font-size: 12px;
    text-decoration: underline;
    color: #000000
}

.products_header {
    font-family: Arial, Verdana, Helvetica, sans-serif;
    font-size: 18px;
    font-weight: normal;
    text-decoration: none;
    color: #F58220
}

.products_header a {
    text-decoration: none;
    color: #F58220
}

.products_header_links {
    font-family: Arial, Verdana, Helvetica, sans-serif;
    font-size: 18px;
    font-weight: normal;
    text-decoration: none;
    color: #F58220
}

.products_header_links a {
    text-decoration: none;
    color: #F58220
}

.products_header_links:hover,
.products_header_links a:hover {
    font-family: Arial, Verdana, Helvetica, sans-serif;
    font-size: 18px;
    text-decoration: underline;
    color: #F58220
}

.products_links {
    font-family: Arial, Verdana, Helvetica, sans-serif;
    font-size: 12px;
    text-decoration: underline;
    color: #333333
}

.products_links a {
    text-decoration: underline;
    color: #333333
}

.products_price {
    font-family: Arial, Verdana, Helvetica, sans-serif;
    font-size: 15px;
    text-decoration: none;
    color: #BF1E2D
}

.products_price.products_price_single {
    font-size: 26px;
}

.path {
    padding-left: 10px
}

.path_text {
    font-family: Arial, Verdana, Helvetica, sans-serif;
    font-size: 12px;
    text-decoration: none;
    font-weight: bold;
    color: #BF1E2D
}

.path_text:active,
.path_text:hover {
    font-family: Arial, Verdana, Helvetica, sans-serif;
    font-size: 12px;
    text-decoration: underline;
    font-weight: bold;
    color: #BF1E2D
}

.path_arrow {
    font-family: Arial, Verdana, Helvetica, sans-serif;
    font-size: 11px;
    text-decoration: none;
    color: #000000
}

.product_detail_price {
    font-family: Arial, Verdana, Helvetica, sans-serif;
    font-size: 26px;
    text-decoration: bold;
    color: #BF1E2D;
}

.footer_links {
    font-family: Arial, Verdana, Helvetica, sans-serif;
    font-size: 12px;
    text-decoration: none;
    color: #BF1E2D
}

.footer_links:hover {
    font-family: Arial, Verdana, Helvetica, sans-serif;
    font-size: 12px;
    text-decoration: underline;
    color: #BF1E2D
}

.message {
    font-family: Arial, Verdana, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
    color: #FF0000
}

div.divvidljivekategorije {
    line-height: 22px
}

div.divvidljivekategorije div {
    text-align: right
}

.chk_rb_left_sidebar {
    font-size: 12px
}

.chk_rb_left_sidebar:hover {
    font-size: 12px;
    text-decoration: underline;
    font-weight: bold
}

.ponisti {
    font-size: 12px;
    color: #999999;
    text-decoration: underline
}

.ponisti:hover {
    font-size: 12px;
    color: #000000;
    text-decoration: underline;
    font-weight: bold
}

.mega_countdown_css {
    font-size: 16px;
    color: #000000;
    text-decoration: none;
    font-weight: bold
}

.main_product_details_mega_discount {
    font-size: 12px;
    color: #FFFFFF;
    text-align: left;
    vertical-align: middle;
    padding-left: 5px;
    padding: 5px;
    text-decoration: none;
    font-weight: normal
}

.main_product_details_mega_discount_css {
    font-size: 14px;
    color: #FFFFFF;
    text-decoration: none;
    font-weight: bold
}

.main_product_details_mega_discount_css_big {
    font-size: 16px;
    color: #FFFFFF;
    text-decoration: none;
    font-weight: bold
}

.main_product_details_mega_discount_err {
    font-size: 18px;
    color: #FFFFFF;
    text-decoration: none;
    font-weight: bold
}

.obavestenje_proizvod_na_akciji {
    padding-left: 3px;
    padding-bottom: 3px;
    padding-top: 20px;
    padding-right: 3px
}

.obavestenje_proizvod_na_akciji a {
    color: black;
    font-size: 12px;
    color: #BF1E2D;
    text-decoration: none;
    font-weight: bold
}

.obavestenje_proizvod_na_akciji a:hover {
    color: black;
    font-size: 12px;
    color: #BF1E2D;
    text-decoration: underline;
    font-weight: bold
}

#email_obavestenje_container {
    display: none;
    font-family: Arial, Verdana, Helvetica, sans-serif;
    font-size: 12px;
    padding: 2px;
    text-align: left;
    text-decoration: none;
    color: #000000;
    vertical-align: middle
}

.product_mark {
    display: inline-block;
    padding: 2px 10px;
    color: #fff;
    font-size: 14px;
    line-height: 1;
    font-weight: bold;
    text-transform: uppercase
}

.product_new {
    background: #F58220
}

.product_action {
    background: #F58220
}

.product_action_red {
    background: #ff0000
}

.product_mega_discount {
    background: #ff0000;
    padding: 2px 2px
}

.product_sold {
    background: #61747d
}

.m0 {
    margin: 0
}

.listanje-proizvoda-main {
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%
}

.menu-breadcrumbs {
    padding: 5px
}
.page-breadcrumbs {
    margin-bottom: 5px;
}

.webnipretragainput .easy-autocomplete  {
    max-width: 100%;
}

@-webkit-keyframes zoom {
    0% {
        -webkit-transform: scale(0)
    }
    to {
        -webkit-transform: scale(1)
    }
}

@keyframes zoom {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0)
    }
    to {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .webnipretragainput {
          width: calc(100% - 220px);
    }

    .webnipretragainput .easy-autocomplete {
        width: 100% !important;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

.rating-popup {
    display: none;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0px 0px 10px -4px #333;
    margin-top: 10px;
    padding: 10px
}

#rateYo:hover ~ .rating-popup {
    display: inline-block
}

@media (max-width: 1120px) {
    .desnoodslike,
    .ispodslike,
    .proizvodlevo {
        margin: auto;
        width: 100%
    }

    .desnoodslike {
        width: 80%;
        margin: auto;
        margin-top: 20px
    }

    .proizvodlevo {
        width: 100%
    }

    .slikaproizvoda {
        margin: auto
    }
}

@media (max-width: 575px) {
    div#carousel li {
        width: 100px !important
    }
}

.slikaproizvoda img {
    width: 100%
}

div#slider {
    margin-bottom: 10px
}

#slider .slides {
    display: block;
    overflow: hidden
}

#carousel.flexslider .slides > li {
    max-width: 115px;
    overflow: hidden
}

#slider.flexslider .slides img {
    width: auto;
    display: inline-block;
    max-width: 100%
}

.flexslider .slides img {
    max-width: 100%;
    width: auto !important;
    display: block
}

/* .hidden {
    display: none;
} */

.slides,
.slides > li {
    margin: 0;
    padding: 0;
    list-style: none;
    display: -webkit-box !important;
    display: -moz-box !important;
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center
}

.slider img {
    width: 100%;
    height: auto
}

.slides li {
    position: relative;
    text-align: center;
    max-height: 600px
}

.slides li a {
    display: block;
    text-align: center;
    width: 100%
}


#carousel.flexslider .slides > li img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

div#slider {
    padding: 50px 0;
}

/* Slider */
.mySlides {
    display: none;
}

.slideshow-container {
    max-width: 1000px;
    position: relative;
    margin: auto;
}

.numbertext {
    color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
}

.dot {
    height: 15px;
    width: 15px;
    margin: 0 2px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
}

@-webkit-keyframes fade {
    from {
        opacity: .4
    }
    to {
        opacity: 1
    }
}

@keyframes fade {
    from {
        opacity: .4
    }
    to {
        opacity: 1
    }
}

/*
    Custom Slideshow
*/

.slideshow-container {
    width: 800px;
    height: 360px;
    max-width: 100%;
    position: relative;
    margin: 0 auto;
    overflow: hidden;
    margin-bottom: 20px;
}

.slideshow-container .mySlides {
    width: 800px;
    max-width: 100%;
    height: 330px;
    overflow: hidden;
    -webkit-transition: all 1s ease-out;
    -moz-transition: all 1s ease-out;
    -o-transition: all 1s ease-out;
    transition: all 1s ease-out;
    position: absolute;
}

.slideshow-navigation {
    margin-top: 10px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.slideshow-container img {
    width: 100%;
    height: 100%;
}

@keyframes slideleft {
    from {
        left: -600px
    }
    to {
        left: 0
    }
}

@-webkit-keyframes slideleft {
    from {
        left: -600px
    }
    to {
        left: 0
    }
}

@keyframes slideright {
    from {
        right: -600px
    }
    to {
        right: 0
    }
}

@-webkit-keyframes slideright {
    from {
        right: -600px
    }
    to {
        right: 0
    }
}

@keyframes slidetop {
    from {
        top: -330px
    }
    to {
        top: 0
    }
}

@-webkit-keyframes slidetop {
    from {
        top: -330px
    }
    to {
        top: 0
    }
}

@keyframes slidebottom {
    from {
        bottom: -330px
    }
    to {
        bottom: 0
    }
}

@-webkit-keyframes slidebottom {
    from {
        bottom: -330px
    }
    to {
        bottom: 0
    }
}

@-webkit-keyframes blur {
    from {
        -webkit-filter: blur(5px);
        filter: blur(5px);
    }
    to {
        -webkit-filter: blur(0);
        filter: blur(0);
    }
}

@-moz-keyframes blur {
    from {
        -webkit-filter: blur(5px);
        filter: blur(5px);
    }
    to {
        -moz-filter: blur(0);
        filter: blur(0);
    }
}

@keyframes blur {
    from {
        -webkit-filter: blur(5px);
        -moz-filter: blur(5px);
        filter: blur(5px);
    }
    to {
        -webkit-filter: blur(0);
        -moz-filter: blur(0);
        filter: blur(0);
    }
}


.mega-popust-ispod-menija li {
    background: #bf1e2d;
    border-bottom: solid 5px #a20c1a;
}

.ostali-ispod-menija li {
    background: #f58220;
    border-bottom: solid 5px #dc6a09;
}

.mega-popust-ispod-menija-mob li {
    background: #bf1e2d;
}

li.btn-mega-akcija {
  background: #bf1e2d;
  border-bottom: solid 5px #a20c1a;
}
li.btn-ostale-akcije {
  background: #f58220;
  border-bottom: solid 5px #dc6a09;
}

.ostali-ispod-menija-mob li {
    background: #f58220;
}

.left-menu-brands {
    list-style: none;
    padding: 0;
    margin: 0;
}

.left-menu-brands li {
    /*padding-left: 15px;*/
    /*padding-top: 3px;*/
    text-align: left;
}

.footer-cards-holder {
    text-align: left;
    display: inline;
}

.footer-cards-holder .img1 {
    width: 88px;
    height: 48px;
    padding-right: 15px;
}

.footer-cards-holder .img2 {
    width: 88px;
    height: 48px;
    padding-right: 30%;
}

.footer-cards-holder .img3 {
    width: 75px;
    height: 48px;
    right: 345px;
}

.footer-cards-holder .img4 {
    width: 75px;
    height: 48px;
    right: 255px;
}

.footer-cards-holder .img5 {
    width: 76px;
    height: 48px;
    right: 165px;
}

.footer-cards-holder .img6 {
    width: 76px;
    height: 48px;
    right: 80px;
}


.inline {
    display: inline;
}

.no-border {
    border-style: none;
}

.webnipretraga-search-form {
    margin: 0px;
    padding: 0px;
}

.webnipretraga-search-button {
    border: none !important;
    padding: 0px !important;
}

/* .preracunaj input {
    width: 100%;
    height: 25px;
    text-align: center;
    color: #fff;
    line-height: 25px;
}

.preracunaj {
    position: relative;
    height: 25px;
    text-align: center;
    color: #fff;
    line-height: 25px;
} */

/* .sign-in-form {
    width: 100%;
    max-width: 350px;
} */

.dugmedodajukorpu {
    display: inline-block;
    margin-right: 10px;
    float: left;
}

.dugmedodajukorpu input {
    height: 30px;
}

.hide-on-desktop {
    display: none;
}

.sifra-proizvoda {
    color:#666;
    font-size:12px;
    /*float:left;*/
    width:100%;
    margin-bottom:10px;
}

.webnipretragainput {
    left: 80px;
    top: 5px;
}

.pretraga {
    margin-bottom: 20px;
}

.webnibredkumbs {
    /* margin-top: -10px; */
    margin-bottom: 10px;
    height: 30px;
    line-height: 30px;
}

.centralnideobezdesnekolone {
    margin-top: -15px;
}

.webnipretragakorpa {
    position: relative;
}

.cart-counter {
    position: absolute;
    top: 0;
    right: 0;
    width: 15px;
    height: 15px;
    line-height: 15px;
    text-align: center;
    border-radius: 50%;
    background-color: #BF1E2D;
    color: #fff;
    font-size: 11px;
    font-weight: 700;
}

@media (max-width: 1120px) {
    .desnoodslike {
        width: 100%;
    }
}

@media(max-width: 850px){
    .webnipretragainput {
        left: 10px;
    }
}

@media(max-width: 620px){
    .webnibredkumbs {
        display: block;
        padding: 10px;
    }
    div#slider {
        padding: 10px 0 !important;
    }
    .hide-on-desktop {
        display: block;
    }
    .hide-on-mobile {
        display: none;
    }
    .sifra-proizvoda {
        float: none;
        margin-bottom: 10px;
    }
    .webnibredkumbs a,
    .webnibredkumbs span {
        display: none;
    }
    .webnibredkumbs > a:last-of-type {
        display: block !important;
    }
    .mobile-title {
        margin-bottom: 10px !important;
    }
    .proizvodlevo .flexslider {
        margin-bottom: 0;
    }
    .centralnideobezdesnekolone {
        margin-top: -5px;
    }
}

@media(max-width: 400px){
    .webnipretragainput {
        top: 2px;
    }
    .webnipretragakorpa,
    .webnipretragadugme {
        margin-top: -1px;
    }
    .pretraga {
        margin-bottom: 10px;
    }
}

/* Pagination */

div.pagination {
    background-color: #BF1E2D;
    padding: 5px 10px;
    display: flex;
    color: #fff;
    font-size: 12px;
    align-items: center;
}

nav.pagination {
    display: block;
    background-color: #BF1E2D;
    text-align: center;
}

.pagination-brands nav.pagination {
    padding: 5px 0;
}

.pagination-brands.pagination-top {
    margin-bottom: 20px;
}

.pagination > div:first-child {
    width: 150px;
}

.pagination > div:nth-child(2) {
    flex: 1;
    text-align: center;
}

.pagination-sort {
    width: 150px;
}

.pagination__item {
    font-size: 14px;
    font-weight: 700;
    width: 25px;
    height: 25px;
    line-height: 25px;
    display: inline-block;
    border-radius: 4px;
    background-color: #f58220;
    border: 1px solid #f58220;
    margin: 0 2px;
    color: #fff;
    text-decoration: none;
    text-align: center;
}

.pagination__item.pagination__item--current-page {
    background-color: transparent;
    border: 1px solid #fff;
}

.pagination__item--previous-page:before {
    content: '<';
}

.pagination__item--next-page:before {
    content: '>';
    
}

/* .pagination:after {
    content: "";
    clear: both;
    display: table;
} */


.pagination-sort {
    width: 150px;
    float: left;
}

.pagination > * {
    box-sizing: border-box;
}

.pagination-sort select {
    border: 1px solid #ddd;
    border-radius: 3px;
    color: #555;
    width: 100%;
    padding: 2px;
}

.form-wrapper label {
    display: block;
    padding: 2px 0;
}

.form-element {
    margin: 10px 0;
}

.form-notice span {
    color: #A51129;
    margin: 10px 0;
}

.form-notice {
    margin: 10px 0;
}

.webnimeni ul,
.malimenuproizvodi ul {
    padding: 5px;
    margin: 0;
    list-style: none;
}

.webnimeni ul li,
.malimenuproizvodi ul li {
    padding: 5px;
    border-bottom: 1px solid #dadada;
}

.webnimeni ul li img,
.malimenuproizvodi ul li img {
    float: right;
    margin-top: 7px;
}

@media(max-width: 991px){


    .pagination-sort {
        width: 125px;
        float: left;
    }
}

@media(max-width: 768px){
    .pagination {
        height: 30px;
        box-sizing: border-box;
        position: relative;
    }


    .pagination-sort {
        width: 50%;
        float: left;
    }
    .pagination-sort {
        text-align: right;
        padding-right: 5px;
    }
    .pagination-sort select {
        width: 150px;
    }


    .pagination-bottom .pagination-sort {
        display: none;
    }

    #carousel ul {
        max-width: 100% !important;
        display: block !important;
        width: 100% !important;
        transform: none !important;
        font-size: 0;
    }

    @media all and (max-width: 620px) and (min-width: 501px) {
        .centralnideobezdesnekolone {
            max-width: 100% !important;
        }
    }
}

.grey_hr {
    border: none;
    height: 1px;
    /* Set the hr color */
    color: #c1c1c1; /* old IE */
    background-color: #c1c1c1; /* Modern Browsers */
}

/* Home Page */
.home-page-products {
    position: relative;
    flex-wrap: wrap;
    flex-direction: row;
    display: flex;
    padding: 0 10px;
}

.home-page-product {
    width: 25%;
    padding: 10px;
    margin-bottom: 30px;
    box-sizing: border-box;
    position: relative;
}

.home-page-product .product_image {
    width: 200px;
    text-align: center;
    margin: 0 auto 20px auto;
    position: relative;
}

.home-page-product .product_image img {
    max-width: 100%;
    height: 200px;
}

.home-page-product .products_header {
    text-align: center;
}

.home-page-product .products_header a {
    font-size: 18px;
    text-decoration: none;
    color: #000;
    font-weight: 700;
}

.home-page-product .naslovniproizvodcena {
    position: relative;
    text-align: center;
}

.naslovniproizvodcena .main_ordered_price {
    font-size: 24px;
}

.home-page-product::before {
    content: '';
    width: 1px;
    height: 95%;
    border-left: 1px solid #666;
    position: absolute;
    top: 0;
    left: 0;
}

.home-page-product::after {
    content: '';
    width: 95%;
    height: 1px;
    border-bottom: 1px solid #666;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.home-page-product:nth-child(1)::before,
.home-page-product:nth-child(5)::before,
.home-page-product:nth-child(9)::before,
.home-page-product:nth-child(13)::before,
.home-page-product:nth-child(17)::before,
.home-page-product:nth-child(21)::before {
    opacity: 0;
}

@media(max-width: 1280px){
    .home-page-product {
        width: 33.33%;
    }

    .home-page-product:nth-child(4)::before,
    .home-page-product:nth-child(7)::before,
    .home-page-product:nth-child(10)::before,
    .home-page-product:nth-child(13)::before,
    .home-page-product:nth-child(16)::before,
    .home-page-product:nth-child(19)::before,
    .home-page-product:nth-child(22)::before {
        opacity: 0;
    }

    .home-page-product:nth-child(5)::before,
    .home-page-product:nth-child(9)::before,
    .home-page-product:nth-child(17)::before,
    .home-page-product:nth-child(21)::before  {
        opacity: 1;
    }
}

@media(max-width: 991px){
    .home-page-product {
        width: 50%;
    }

    .home-page-product:nth-child(3)::before,
    .home-page-product:nth-child(5)::before,
    .home-page-product:nth-child(9)::before,
    .home-page-product:nth-child(11)::before,
    .home-page-product:nth-child(15)::before,
    .home-page-product:nth-child(17)::before,
    .home-page-product:nth-child(21)::before,
    .home-page-product:nth-child(23)::before {
        opacity: 0;
    }

    .home-page-product:nth-child(4)::before,
    .home-page-product:nth-child(10)::before,
    .home-page-product:nth-child(16)::before,
    .home-page-product:nth-child(22)::before {
        opacity: 1;
    }
}

@media(max-width: 560px){
    .home-page-product {
        width: 100%;
    }
    .home-page-product::before {
        opacity: 0 !important;
    }
}

/* Archive Products */

.category-product .podkategorijaproizvodslika {
    width: 200px;
    height: 200px;
    margin-bottom: 20px;
    position: relative;
}

.category-product .podkategorijaproizvodslika img {
    max-width: 100%;
    max-height: 200px;
}

.category-product .products_header_links {
    color: #000;
    font-size: 18px;
    font-weight: 700;
}

.category-product.podkategorijaproizvod:after {
    top: 0;
    height: 99%;
    border-left: 1px solid #666;
}

.category-product.podkategorijaproizvod {
    border-bottom: solid 1px #666;
}

.category-product ul {
    list-style-type: none;
    padding: 0;
    text-align: left;
}

.category-product ul * {
    box-sizing: border-box;
}
.category-product .varijacija {
    font-size: 0;
}

.category-product .varijacija::after {
    content: "";
    clear: both;
    display: table;
}

.category-product .varijacija .varijacija-levo {
    font-size: 14px;
    color: #bf1e2d;
    width: 65%;
    display: block;
    float: left;
}

.category-product .varijacija .varijacija-desno {
    width: 33%;
    display: block;
    text-align: right;
    font-size: 12px;
    line-height: 14px;
    float: left;
}

.category-product .varijacija .cena-varijacije {
    color: #bf1e2d;
}

.category-product .varijacija span > a {
    color: #333;
}

/* Share Product */
.social-share {
    position:relative
}
.social-share-links {
    position:absolute;
    top:0;
    left:60px;
    display:none;
    -webkit-box-shadow:0 0 5px 0 rgba(0,0,0,0.75);
    -moz-box-shadow:0 0 5px 0 rgba(0,0,0,0.75);
    box-shadow:0 0 5px 0 rgba(0,0,0,0.75);
    padding:5px
}
.toggle-social-share {
    background-color:transparent;
    border:0;
    width:40px;
    height:40px;
    cursor:pointer
}
.toggle-social-share img {
    max-width:100%
}
.social-share-links ul {
    margin:0;
    padding:0;
    list-style: none;
}
.social-share-links ul li {
    height:30px;
    line-height:30px;
    float: left;
    margin-right:5px
}
.social-share-links ul li img {
    max-height:100%
}

.div_subcat_proizvodi a {
    color: #333;
    text-decoration: none;
    font-size: 12px;
    font-weight: 700;
}
.divvidljivekategorije a:hover,
.div_subcat_proizvodi a:hover {
    text-decoration: underline;
}

.divvidljivekategorije ul,
.div_subcat_proizvodi ul {
    list-style-type: none;
    padding: 0;
}

.div_subcat_proizvodi span {
    display: block;
    text-align: left;
    font-size: 13px;
    padding: 10px 0;
    font-weight: bold;
    color: #bf1e2d;
    
}

.div_subcat_proizvodi span a {
    color: #f58220;
    display: block;
    text-align: right;
}
.divvidljivekategorije li {
    line-height: 16px;
}
.divvidljivekategorije a {
    color: #bf1e2d;
    text-decoration: none;
    font-size: 12px;
}

#eac-container-keywords ul {
    list-style-type: none;
    margin: 0;
}

#eac-container-keywords ul li {
    background-color: #fff;
    padding: 5px;
}

#easy-autocomplete input {
    width: 100%;
}

#eac-container-keywords {
    box-sizing: border-box;
}

.slicniproizvodi-wrap {
    display: flex;
}

.slicniproizvodi-wrap > div {
    width: 33.33%;
}

.slicniproizvodi-wrap > div {
    padding-right: 20px;
}

.slicniproizvodi-wrap h3 a {
    font-size: 12px;
    color: #bf1e2d;
    text-decoration: none;
}

.slicniproizvodi-wrap .main_ordered_category_link {
    color: #333;
}

.dugmedodajukorpu, .single-product-col {
  display: inline-block;
  margin-right: 10px;
  float: left;
}

.clearfloat::after {
  content: "";
  clear: both;
  display: table;
}

h1.main_ordered_header {
  font-family: Arial,Verdana,Helvetica,sans-serif;
  font-size: 18px;
  font-weight: normal;
  padding-left: 10px;
  text-decoration: none;
  color: #f58220;
}

/*
* Rating - Start
*/
.jq-ry-container {
    position:relative;
    padding:0 5px;
    line-height:0;
    display:block;
    cursor:pointer;
    -webkit-box-sizing:content-box;
    -moz-box-sizing:content-box;
    box-sizing:content-box
}
.jq-ry-container[readonly=readonly] {
    cursor:default
}
.jq-ry-container>.jq-ry-group-wrapper {
    position:relative;
    width:100%
}
.jq-ry-container>.jq-ry-group-wrapper>.jq-ry-group {
    position:relative;
    line-height:0;
    z-index:10;
    white-space:nowrap
}
.jq-ry-container>.jq-ry-group-wrapper>.jq-ry-group>svg {
    display:inline-block
}
.jq-ry-container>.jq-ry-group-wrapper>.jq-ry-group.jq-ry-normal-group {
    width:100%
}
.jq-ry-container>.jq-ry-group-wrapper>.jq-ry-group.jq-ry-rated-group {
    width:0;
    z-index:11;
    position:absolute;
    top:0;
    left:0;
    overflow:hidden
}

.average_stars_no {
    color:#8f8f8f;
    text-align:center;
    display:block;
    margin-bottom:5px
}
/*
* Rating - End
*/

/*
* Search - Start
*/
.easy-autocomplete-container {
    position:relative;
    z-index:999999
}
.easy-autocomplete-container ul {
    background:none repeat scroll 0 0 #fff;
    border-top:1px dotted #ccc;
    display:none;
    margin-top:0;
    padding-bottom:0;
    padding-left:0;
    position:relative;
    top:-1px;
}
.pretraga_item_img {
    float:left;
    width:60px;
    height:60px;
    overflow:hidden;
    text-align:center
}
.pretraga_item_desc {
    float:left;
    width:calc(100% - 70px)
}
.pretraga_item_title {
    margin:0 0 5px 10px;
    font-weight:bold
}
.easy-autocomplete-container ul li:hover,
.easy-autocomplete-container ul li.selected {
    background: #ebebeb !important;
    cursor:pointer;
}
.easy-autocomplete-container ul .eac-category,
.easy-autocomplete-container ul li {
    background:inherit;
    border-color:#ccc;
    border-image:none;
    border-style:solid;
    border-width:0 1px;
    display:block;
    font-size:14px;
    font-weight:normal;
    padding:4px 12px
}
.easy-autocomplete-container ul li div {
    display:block;
    font-weight:normal;
    word-break:break-all
}
.easy-autocomplete-container ul .eac-category,
.easy-autocomplete-container ul li {
    font-size:14px;
    font-weight:normal
}
.pretraga_item_img {
    float:left;
    width:60px;
    height:60px;
    overflow:hidden;
    text-align:center
}
.pretraga_item_img img {
    width:auto;
    max-width:100%
}
.pretraga_item_desc {
    float:left;
    width:calc(100% - 70px)
}
.pretraga_item_title {
    font-size:15;
    margin:0 0 5px 10px;
    font-weight:bold
}
.pretraga_item_btns span {
    margin-left:10px;
    font-size:12px;
    color:#fff;
    display:inline-block;
    padding:3px 6px;
    text-transform:uppercase
}
.btns_search_akcija {
    background:#f58220;
    border-bottom:2px solid #dc6a09
}
.btns_search_popust {
    background:#bf1e2d;
    border-bottom:2px solid #a20c1a
}
.pretraga_item:after {
    content: "";
    clear: both;
    display: table;
}
.pretraga_item_title a {
    color: inherit;
    text-decoration: none;
}

/*
* Search - End
*/

/*
* Brands - Start
*/

.brands-wrapper {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    width: 100%;
}

.brands-wrapper .brand {
    width: 25%;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
    position: relative;
}

.brand:nth-child(4n):before {
    display: none;
}

.brand:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 90%;
    height: 1px;
    background-color: #dadada;
    right: 0;
    margin: auto;
}

.brand:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    height: 90%;
    background-color: #dadada;
    bottom: 0;
    margin: auto;
}

.brands-wrapper {
    border-top: 1px solid #dadada;
    border-bottom: 1px solid #dadada;
}

/*
* Brands - End
*/

/*
* All categories - Start
*/
.category-parent-header {
    display: flex;
    align-items: center;
}

.category-parent-header h2 {
    border-bottom: 0;
    font-size: 18px;
}

.category-parent {
    border-bottom: 1px solid #dadada;
    margin-bottom: 10px;
}
.subcategories-wrap {
    display: flex;
}
.subcategories-wrap ul {
    width: 50%;
    list-style-type: none;
    padding-left: 20px;
}

.subcategories-wrap ul a {
  text-decoration: none;
  color: #bf1e2d;
  font-size: 12px;
}

/*
* All categories - End
*/

/*
* Sliders - Start
*/

.slideshow-container {
 max-width:1000px;
 position:relative;
 margin:auto
}
.numbertext {
 color:#f2f2f2;
 font-size:12px;
 padding:8px 12px;
 position:absolute;
 top:0
}
.dot {
 height:15px;
 width:15px;
 margin:0 2px;
 background-color:#bbb;
 border-radius:50%;
 display:inline-block;
 transition:background-color .6s ease
}
.active {
 background-color:#717171
}


.slideshow-container {
 width:800px;
 height:360px;
 max-width:100%;
 position:relative;
 margin:0 auto;
 overflow:hidden;
 margin-bottom:20px
}
.slideshow-container .mySlides {
 width:800px;
 max-width:100%;
 height:330px;
 overflow:hidden;
 -webkit-transition:all 1s ease-out;
 -moz-transition:all 1s ease-out;
 -o-transition:all 1s ease-out;
 transition:all 1s ease-out;
 position:absolute;
visibility: hidden;
}
.slideshow-navigation {
 margin-top:10px;
 position:absolute;
 bottom:0;
 left:0;
 right:0
}
.slideshow-container img {
 width:100%;
 height:100%
}

.slideshow-container .slider-active {
 display:block;
 visibility: visible;
}
.slideleft,
.slideright,
.slidetop,
.slidebottom,
.slideblur,
.fade {
 display:block;
 z-index:1
}

.fade.slider-active {
-webkit-animation-name:fade;
 -webkit-animation-duration:1.5s;
 animation-name:fade;
 animation-duration:1.5s;
 z-index: 10;
}

.slideblur.slider-active {
 -webkit-animation-name:blur;
 -webkit-animation-duration:1.5s;
 animation-name:blur;
 animation-duration:1.5s;
 z-index: 10;
}
.slideleft.slider-active {
 -webkit-animation-name:slideleft;
 -webkit-animation-duration:1.5s;
 animation-name:slideleft;
 animation-duration:1.5s;
 z-index: 10
}
.slideright.slider-active {
 -webkit-animation-name:slideright;
 -webkit-animation-duration:1.5s;
 animation-name:slideright;
 animation-duration:1.5s;
 z-index: 10;
}
.slidetop.slider-active {
 -webkit-animation-name:slidetop;
 -webkit-animation-duration:1.5s;
 animation-name:slidetop;
 animation-duration:1.5s;
 z-index: 10;
}
.slidebottom.slider-active {
 -webkit-animation-name:slidebottom;
 -webkit-animation-duration:1.5s;
 animation-name:slidebottom;
 animation-duration:1.5s;
 z-index: 10;
}
@-webkit-keyframes fade {
 from {
  opacity:.4
 }
 to {
  opacity:1
 }
}
@keyframes fade {
 from {
  opacity:.4
 }
 to {
  opacity:1
 }
}
@keyframes slideleft {
 from {
  left:-600px
 }
 to {
  left:0
 }
}
@-webkit-keyframes slideleft {
 from {
  left:-600px
 }
 to {
  left:0
 }
}
@keyframes slideright {
 from {
  right:-600px
 }
 to {
  right:0
 }
}
@-webkit-keyframes slideright {
 from {
  right:-600px
 }
 to {
  right:0
 }
}
@keyframes slidetop {
 from {
  top:-330px
 }
 to {
  top:0
 }
}
@-webkit-keyframes slidetop {
 from {
  top:-330px
 }
 to {
  top:0
 }
}
@keyframes slidebottom {
 from {
  bottom:-330px
 }
 to {
  bottom:0
 }
}
@-webkit-keyframes slidebottom {
 from {
  bottom:-330px
 }
 to {
  bottom:0
 }
}
@-webkit-keyframes blur {
 from {
  -webkit-filter:blur(5px);
  filter:blur(5px)
 }
 to {
  -webkit-filter:blur(0);
  filter:blur(0)
 }
}
@-moz-keyframes blur {
 from {
  -webkit-filter:blur(5px);
  filter:blur(5px)
 }
 to {
  -moz-filter:blur(0);
  filter:blur(0)
 }
}
@keyframes blur {
 from {
  -webkit-filter:blur(5px);
  -moz-filter:blur(5px);
  filter:blur(5px)
 }
 to {
  -webkit-filter:blur(0);
  -moz-filter:blur(0);
  filter:blur(0)
 }
}

.deklaracija-modal {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    box-sizing: border-box;
    padding: 10px;
    z-index: 20;
}

.deklaracija-modal_content {
    background-color: #fff;
    border-radius: 10px;
    width: 100%;
    max-width: 600px;
    padding: 10px;
    margin: auto;
    top: 50%;
    transform: translateY(-50%);
    position: relative;
    box-sizing: border-box;
}

.btn-close-deklaracija {
    position: absolute;
    right: 5px;
    top: 5px;
    width: 20px;
    height: 20px;
    color: #fff;
    border-radius: 50%;
    font-weight: 700;
    background-color: #000;
    text-align: center;
    text-decoration: none;
}
/*
* Cart popup - Start
*/
.jconfirm-holder {
    max-width: 500px;
    width: 100%;
    margin: auto;
    padding: 50px 10px !important;
    box-sizing: border-box;
}
/*
* Cart popup - End
*/
/*
* Sliders - End
*/
@media screen and (max-width: 1100px){
    .desnisajdbar {
        display: none;
    }
}
@media(max-width: 991px){
    .pagination-top .pagination-numbers {
        display: none;
    }
    .pagination-top .pagination > div:first-child,
    .pagination-top .pagination > div:last-child {
        width: 50%;
    }

    .pagination-sort select {
        max-width: 200px;
    }

    .pagination-sort {
        text-align: right;
    }

    .pagination-bottom .pagination > div:first-child,
    .pagination-bottom .pagination > div:last-child {
        display: none;
    }

    .pagination-numbers {
        padding-top: 5px;
    }
}
@media(max-width: 768px){
    .brands-wrapper .brand {
        width: 50%;
    }
    .pagination-brands nav.pagination {
        height: auto;
    }
    .brand:nth-child(2n):before {
        display: none;
    }
}

@media(max-width: 620px){
    .slicniproizvodi-wrap {
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 400px){
    .naslovnaslika {
        display: none !important;
    } 
}

/*
* Kontakt - Start
*/
.search_results_header {
  font-family: Arial,Verdana,Helvetica,sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: 30px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 5px;
  text-decoration: none;
  color: #f58220;
  background-color: #EDEDED;
  margin: 30px 0;
}
.contact-form-info-top {
    font-size: 12px;
    padding-left: 5px;
}

#success {
    color: #5CB85C;
}

label.error {
    font-size: 12px;
    color: #A51129;
    position: absolute;
    bottom: -17px;
    display: block;
    font-weight: bold;
}

.form-row-notice {
    font-size: 13px;
}

/*
* Kontakt - End
*/

.none {
    display: none;
}
