@font-face {
    font-family: 'Inter';
    src: url('../../fonts/Inter-Italic.woff2') format('woff2'),
        url('../../fonts/Inter-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../../fonts/subset-Inter-Regular.woff2') format('woff2'),
        url('../../fonts/subset-Inter-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}